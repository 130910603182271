import {
    betslipStore,
} from '~SpectateStore';

import {
    selectCastMarketSelectionIds,
} from '~Betslip/selectors/userSelectionsSelectors';

export const isSelectionSuspended = (selection) => {
    /**
     * Note: market_event_active/tradable flags come from BE markets_event table and are propogated via websocket
     * as market_active/tradable.
     */
    const {
        event_active,
        market_active,
        market_event_active,
        selection_active,
        event_tradable,
        market_event_tradable,
        selection_tradable,
        is_inplay,
    } = selection;

    const isTradable = Boolean(is_inplay) === true
        ? Boolean(selection_tradable) && Boolean(event_tradable) && Boolean(market_event_tradable)
        : true;

    const isActive = Boolean(event_active) && Boolean(market_active) && Boolean(market_event_active) && Boolean(selection_active);

    return !isActive || !isTradable;
};

export const findFcTcSelectionId = (fcTcCombinationToValidate) => {
    const existingFcTcIds = selectCastMarketSelectionIds(betslipStore.getState());
    const toValidate = [...fcTcCombinationToValidate].sort().join('_');

    const fcTcSelectionId = existingFcTcIds.find((existingFcTcId) => {
        const sortedCombination = [...existingFcTcId].sort().join('_');

        return sortedCombination === toValidate;
    });

    return fcTcSelectionId ? fcTcSelectionId.join('_') : null;
};
